.mainwrapper {
  width: 100%;
  height: 100%;
  .backgr {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .mainwrapper {
    margin-top: 60px;
  }
}
