@media screen and (max-width: 780px) {
  .articleswrapper {
    display: flex;
    align-items: auto;
    flex-direction: column;
  }
}
.articleswrapper {
  width: 100%;
  display: flex;

  .articlesLeft {
    flex: 0.5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .articlesRight {
    flex: 1.5;
  }
  .articleLinks {
    text-decoration: none;
    color: black;
    width: 0;
    display: flex;
  }
  .swiper-slide {
    width: 0;
  }
  .swiper-wrapper {
    max-width: fit-content;
  }
  .swiper {
    max-width: 75%;
  }
}
@media screen and (min-width: 781px) and (max-width: 980px) {
  .articleswrapper {
    display: grid;

    flex-direction: column;
  }
  .articlesRight {
    padding-left: 25%;
    align-self: auto;
  }
}
.articleswrapper {
  width: 100%;
  display: flex;

  .articlesLeft {
    flex: 0.5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .articlesRight {
    flex: 1.5;
  }
  .articleLinks {
    text-decoration: none;
    color: black;
  }
}
