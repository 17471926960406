.warrantywrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
}
.warrantyleft {
  width: 80%;
}
.paymentRight {
  width: 80%;
}
.fax {
  width: 100%;
}
