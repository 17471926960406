@media (max-width: 1600px) {
  .b-steps-list {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1600px) {
  .b-steps-list__item:not(:last-child):after {
    width: 120px;
    left: calc(100% - 60px);
  }
}
/* end 992px-1600px */

@media (min-width: 992px) and (max-width: 1199px) {
  .b-steps-list__number {
    font-size: 80px;
  }
  .b-steps-list__item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .b-steps-list__title {
    font-size: 16px;
  }
  .b-steps-list__item:not(:last-child):after {
    top: 40px;
  }
}
/* end 992px-1199px */

@media (max-width: 991px) {
  .b-steps-list__item:not(:last-child):after {
    display: none;
  }
  .b-steps-list__item {
    margin-bottom: 40px;
  }
}
