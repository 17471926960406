.article3wrap {
  margin-top: 100px;
  max-width: 75%;

  display: flex;
  flex-direction: column;
  padding-left: 150px;
  font-size: 16px;
}
@media screen and (max-width: 780px) {
  .article3wrap {
    width: 100%;
    padding: 10px;
    max-width: 100%;
    img {
      width: 75%;
      height: 75%;
      align-self: center;
    }
    h3 {
      margin-top: 15px;
    }
  }
}
