@font-face {
    font-family: 'Undounded Bold';
    src: local('Undounded Bold'), local('Undounded-Bold'), 
        url('Unbounded-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Unbounded Extra';
    
    src: local('Unbounded Extra Light'), local('Unbounded-ExtraLight'),
        url('Unbounded-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;

}
@font-face {
    font-family: 'Unbounded Extra';
    src: local('Unbounded Extra Bold'), local('Unbounded-ExtraBold'),
        url('Unbounded-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Unbounded';
    src: local('Unbounded Light'), local('Unbounded-Light'),
        url('Unbounded-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Unbounded';
    src: local('Unbounded Medium'), local('Unbounded-Medium'),
        url('Unbounded-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Unbounded Semi';
    src: local('Unbounded Semi Bold'), local('Unbounded-SemiBold'),
        url('Unbounded-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Unbounded Black';
    src: local('Unbounded Black'), local('Unbounded-Black'),
        url('Unbounded-Black.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}