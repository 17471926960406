.aboutwrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
}
.tag {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutleft {
  display: flex;
  align-items: center;
  flex-direction: column;

  .aboutinfo {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}
@media screen and (max-width: 780) {
  .aboutwrapper {
    width: 100%;

    margin: 0;
  }
  .aboutinfo {
    width: 100%;
    padding: 15px;
    margin: 0;
    align-items: flex-start;
  }
  .aboutleft {
    padding: 10px;
  }
}
